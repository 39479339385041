import React from 'react';
import './App.css';
// import TextHeader from './component/UI/atoms/text/header/header';
// import TextTitleMolekul from './component/UI/molecules/textTitle/textTitleMolekul';
// import TextSubtitleMolekul from './component/UI/molecules/textSubtitle/textSubtitleMolekul';
// import GithubLogo from './component/UI/atoms/logo/logo';
// import Logo from './component/UI/molecules/logoContentSocialMedia/logoContentSocialMedia'
// import Content from './component/UI/molecules/contentGreeting/contentGretting'
// import Introduction from './component/UI/organisms/introduction/introduction'
// import Header from './component/UI/organisms/header/header'
// import TextGetToKnow from './component/UI/molecules/textGetToKnow/textGetToKnow'
// import Footer from './component/UI/atoms/text/footer/footer'
// import ContentRemember from './component/UI/molecules/contentRemember/contentRemember'
import { HashRouter, Route, Switch } from 'react-router-dom';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers react lazy use while component still waiting to load completely
const Home = React.lazy(() => import('./component/pages/home/home')); 
const Pray = React.lazy(() => import('./component/pages/contentPray/contentPray'));
const Thought = React.lazy(() => import('./component/pages/contentThought/contentThought')); 
const About = React.lazy(() => import('./component/pages/contentAbout/contentAbout')); 
const Organization = React.lazy(() => import('./component/pages/contentOrganization/contentOrganization')); 
const Certificate = React.lazy(() => import('./component/pages/contentCertificate/contentCertificate')); 

function App() {

  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
          <Switch>
              {/* <Route exact path="/detail/:id" name="detail-barang" render={props => <DetailBarang {...props}/>} /> */}
              <Route exact path="/" name="Home" render={props => <Home {...props}/>} />
              <Route exact path="/pray" name="Pray" render={props => <Pray {...props}/>} />
              <Route exact path="/thought" name="Thought" render={props => <Thought {...props}/>} />
              <Route exact path="/about" name="About" render={props => <About {...props}/>} />
              <Route exact path="/organization" name="Organization" render={props => <Organization {...props}/>} />
              <Route exact path="/certificate" name="Certificate" render={props => <Certificate {...props}/>} />
          </Switch>
      </React.Suspense>
    </HashRouter>

    // <div className="App">
    //   {/* <TextHeader></TextHeader>
    //   <TextTitleMolekul></TextTitleMolekul>
    //   <TextSubtitleMolekul text="HELLO"></TextSubtitleMolekul>
    //   <TextSubtitleMolekul text="READERS"></TextSubtitleMolekul>
    //   <TextSubtitleMolekul text="!!"></TextSubtitleMolekul>
    //   <GithubLogo src="github_circle.png" text="https://github.com/dhuki/"></GithubLogo>
    //   <GithubLogo src="facebook_circle.png" text="https://twitter.com/dhukidwir"></GithubLogo>
    //   <GithubLogo src="linkedin_circle.png" text="https://id.linkedin.com/in/dhuki-dwi-rachman-846388168"></GithubLogo>
    //   <GithubLogo src="twitter_circle.png" text="https://twitter.com/dhukidwir"></GithubLogo>
    //   <Logo></Logo>
    //   <Content></Content> */}
    //   {/* <Header></Header>
    //   <Introduction></Introduction> */}
    //   <Home></Home>
    //   <Footer></Footer>
    //   <ContentRemember></ContentRemember>
    // </div>
  );
}

export default App;
